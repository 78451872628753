import { render, staticRenderFns } from "./PageHomepage.vue?vue&type=template&id=045cf398&scoped=true&"
import script from "./PageHomepage.vue?vue&type=script&lang=js&"
export * from "./PageHomepage.vue?vue&type=script&lang=js&"
import style0 from "./PageHomepage.vue?vue&type=style&index=0&id=045cf398&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "045cf398",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFadeTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VFadeTransition})
