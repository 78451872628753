<template>
  <div>
    <login-side-bar/>
    <homeHeader />

    <v-fade-transition mode="out-in">
      <router-view ></router-view>
    </v-fade-transition>

    <homeFooter />

  </div>
</template>

<script>




import loginSideBar from "@/components/auth/loginSideBar";
import homeHeader from "@/components/pages/homepage/UI/homeHeader";
import homeFooter from "@/components/pages/homepage/UI/homeFooter";

export default {
  name: "PageHomepage",
  components : {
    homeHeader,
    loginSideBar,
    homeFooter,
  },
  beforeCreate() {
    // if (this.$route.params) {
    //   if ( this.$route.params.lang) {
    //     if ( this.$route.params.lang.length > 2 ) {
    //       this.$route.params.alias = this.$route.params.lang
    //       this.$route.params.lang = null
    //      this.$router.push({name : "businessPublic", alias : this.$route.params.lang , lang : null})
    //     }
    //   }
    // }
    // console.log(this.$route.params)
    // alert(this.$route.params)
  }


}
</script>

<style lang="scss" scoped>
.main_section {
  width:1100px
}
</style>